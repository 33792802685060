import { MobileLayout } from "components";
import { useGameContext } from "contexts";

export const MobileThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <MobileLayout>
      <div className="relative">
        <div className="text-[60px] text-[#000] text-Ultra-Bold mt-24">
          {endTime}
        </div>
        <div className="text-white text-Ultra-Bold text-[30px] tracking-wider uppercase mb-4">
          Well done!
        </div>
        <div className="text-white text-Graphik-SemiBold text-[24px]">
          Talk to a Virgin
          <br />
          Plus rep for a<br />
          chance to win a<br />
          new smartphone
        </div>

        <div className="absolute -top-[60px] -right-[80px]">
          <img
            className="w-[250px] h-auto"
            src={require("assets/images/ThankYou.png")}
            alt="Thank You"
          />
        </div>
      </div>
    </MobileLayout>
  );
};
