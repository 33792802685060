import { useGameContext } from "contexts";
import QRCode from "react-qr-code";

export const KioskThankYou = () => {
  const { endTime } = useGameContext();

  return (
    <div className="relative">
      <div className="text-[180px] text-[#000] text-Ultra-Bold leading-tight mt-48">
        {endTime}
      </div>
      <div className="text-white text-Ultra-Bold text-[70px] tracking-wider uppercase leading-tight mb-8">
        Well done!
      </div>
      <div className="text-white text-Graphik-Regular text-[70px] leading-tight">
        Talk to a Virgin
        <br />
        Plus rep for a<br />
        chance to win a<br />
        new smartphone
      </div>

      <div className="absolute -top-[150px] -right-[200px]">
        <img src={require("assets/images/ThankYou.png")} alt="Thank You" />
      </div>

      <div className="flex items-center mt-32">
        <div className="p-6 bg-white rounded-xl mr-8">
          <QRCode
            size={240}
            value={process.env.REACT_APP_TY_QR_VALUE}
            viewBox={`0 0 240 240`}
          />
        </div>

        <div className="text-white text-Graphik-Regular text-[70px] leading-tight">
          Or scan for your
          <br />
          chance to win
        </div>
      </div>
    </div>
  );
};
