import { useKioskContext } from "contexts";
import { Fragment } from "react";
import QRCode from "react-qr-code";

export const KioskHome = () => {
  const { sessionId } = useKioskContext();

  return (
    <Fragment>
      <div className="flex items-center justify-center mt-12">
        <video autoPlay loop muted>
          <source src={require("assets/videos/intro.webm")} type="video/webm" />
        </video>
      </div>

      <div className="absolute left-0 right-0 -bottom-[50px] flex items-center justify-center">
        <div className="p-4 bg-white rounded-xl">
          <QRCode
            size={240}
            value={`${window.location.origin}/kiosk-game?sessionId=${sessionId}`}
            viewBox={`0 0 240 240`}
          />
        </div>
      </div>
    </Fragment>
  );
};
