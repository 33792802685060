import clsx from "clsx";
import { useInterval, useMobileDevice } from "hooks";
import { useRef, useState } from "react";

export const Clock = ({ start = false, onUpdateTime = () => null }) => {
  const isMobile = useMobileDevice();
  const minRef = useRef(0);
  const secRef = useRef(0);
  const [timeText, setTimeText] = useState("0:00");

  useInterval(
    () => {
      if (secRef.current >= 59) {
        secRef.current = 0;
        minRef.current += 1;
      } else {
        secRef.current += 1;
      }

      const secText = `0${secRef.current}`.slice(-2);
      const timeText = `${minRef.current}:${secText}`;

      onUpdateTime(timeText);
      setTimeText(timeText);
    },
    1000,
    start
  );

  return (
    <div
      className={clsx(
        "text-[#000] text-Ultra-Bold",
        isMobile ? "text-[40px]" : "text-[100px]"
      )}
    >
      {timeText}
    </div>
  );
};
