import clsx from "clsx";
import { useKioskContext } from "contexts";
import { KIOSK_SCREEN } from "utils";

export const KioskLayout = ({ className, children }) => {
  const { screen } = useKioskContext();

  const isHomePage = screen === KIOSK_SCREEN.HOME;

  return (
    <div
      className={clsx(
        "relative w-full min-h-[120rem] flex flex-col p-24 bg-[#e10a0a] overflow-hidden",
        className
      )}
    >
      <div
        className={clsx("relative w-full", isHomePage && "flex items-center")}
      >
        <div className={clsx(!isHomePage && "mb-12")}>
          <img src={require("assets/images/logo.png")} alt="Logo" />
        </div>

        <div
          className={clsx(
            "text-Ultra-Bold text-[#fff] uppercase leading-none",
            isHomePage ? "text-[60px] ml-auto text-right" : "text-[100px]"
          )}
        >
          Fiesta
          <br />
          Extravaganza
        </div>
      </div>

      <div className="absolute left-24 top-[450px] opacity-20">
        <img
          className="max-w-none w-[2016px] h-auto"
          src={require("assets/images/indian-mandala.png")}
          alt="Indian Mandala"
        />
      </div>

      <div className="relative grow">{children}</div>
    </div>
  );
};
